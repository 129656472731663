// ==========================================================================
// Settings / Config
// ==========================================================================

// Path is relative to the stylesheets directory.
$assets-path: "../"  !default;

// Typefaces
// =============================================================================
$font-roboto: 'Roboto', Arial, sans-serif;
$font-helvetica: 'Helvetica', Arial, sans-serif;

// Typography
// =============================================================================
// Base
$base-font-size: 16px;

$font-size:   25px;
$line-height: 1.36;

$font-size-mobile:   18px;
$line-height-mobile: 1.44;

// Headings
$font-size-h1:  120px  !default;
$font-size-h2:  60px  !default;
$font-size-h3:  50px  !default;
$font-size-h4:  34px  !default;
$font-size-h5:  25px  !default;
$font-size-h6:  16px  !default;

$font-size-subtitle:   21px  !default;
$font-size-small-body: 18px  !default;
$font-size-footer:     14px  !default;

$font-size-button: 21px  !default;

$font-size-h1-mobile:  50px  !default;
$font-size-h2-mobile:  30px  !default;
$font-size-h3-mobile:  30px  !default;
$font-size-h4-mobile:  24px  !default;
$font-size-h5-mobile:  21px  !default;
$font-size-h6-mobile:  16px  !default;

// $font-size-subtitle-mobile:   16px  !default;
$font-size-small-body-mobile: 16px  !default;
// $font-size-footer-mobile:     14px  !default;

$font-size-button-mobile: 18px  !default;

// Weights
$weight-light:  300;
$weight-normal: 400;
$weight-medium: 500;
$weight-bold:   700;
$weight-black:  900;

// Spacing Units
// =============================================================================
$unit:       60px;
$unit-small: 20px;

// Sizes
// =============================================================================
$header-height: 98px;
$header-height-mobile: $header-height * 0.6;

// Indexes
// =============================================================================
$zIndex-max: 9999;