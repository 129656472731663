.o-accordeon {
    margin-top: $unit-small * 2;

    &__question {
        @include flex(flex-start, center, row, nowrap);

        width: 100%;
        padding: $unit-small ($unit-small * 2);

        border: solid 1px $light-grey;

        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        cursor: pointer;

        background-color: $white;

        @include hocus {
            background-color: darken($white, 2);
        }

        transition: border-bottom-left-radius $speed $easing,
                    border-bottom-right-radius $speed $easing,
                    background-color $speed $easing;

        @media #{$tabletWMQ} {
            padding: $unit-small;
        }

        span {
            transform: rotate(0) translateZ(0);
            transition: transform $speed $easing;
            color:#000;

            svg {
                width: 11px;
                stroke:#000;
            }
        }
    }

    &__title {
        text-align: left;

        @media #{$tabletWMQ} {
            @include font-size(16px);
            padding-right: $unit-small;
        }
    }

    &__icon {
        display: block;
        position: relative;

        width: 19px;
        height: 19px;

        margin-left: auto;

        opacity: 0.4;

        @media #{$mobileMQ} {
            flex: 1 0 5%;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;

            top: 8px;
            left: 0;

            width: 100%;
            height: 3px;

            background-color: $dark-grey;
            transition: transform $speed $easing;
        }

        &:after {
            transform: rotate(90deg);
        }
    }

    &__answer {
        border-style: solid;
        border-width: 1px;
        border-color: transparent;
        border-top: none;

        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        overflow: hidden;
        max-height: 0;

        transition: max-height $speed $easing,
                    border-color $speed $easing;
    }

    &__answer-inner {
        padding: $unit-small * 2;

        @media #{$tabletWMQ} {
            padding: $unit-small;
        }
    }

    &__single{
        position: relative;

        &.is-opened {
            .o-accordeon__question {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            .o-accordeon__icon {
                &::before {
                    transform: rotate(-180deg) translateZ(0);
                }
                &::after {
                    transform: rotate(0deg) translateZ(0);
                }
            }
            .o-accordeon__answer {
                border-color: $light-grey;

                transition: max-height $speed $easing 0.1s,
                            border-color $speed $easing;
            }
        }
    }
}