.c-navigation {
    position: fixed;
    z-index: $zIndex-max;

    top: 0;
    left: 0;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @include flex(flex-start, center, row, nowrap);

    width: 100%;
    height: $header-height;

    background-color: $red;

    padding: 0 ($unit-small * 2.5);

    @include font-size(18px);
    color: $white;

    @media #{$tabletHMQ} {
        height: $header-height-mobile;
        padding: 0 $unit-small;

        &::after {
            content: '';

            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 1px;

            background-color: $white;

            opacity: 0;
            @include transition(opacity, $speed, $easing, 0.3s);

            z-index: 1;

            .is-menu-open & {
                opacity: 1;
                @include transition(opacity, $speed, $easing, 0.6s);
            }
        }
    }

    &__links {
        width: 90%;
        @include flex(space-between, flex-end, row, nowrap);

        z-index: 1;
    }

    &__logo {
        display: inline-block;

        width: 201px;
        height: 35px;

        z-index: 1;

        @media #{$tabletHMQ} {
            width: 110px;
            height: 19px;
        }

        svg {
            width: 100%;
            height: 100%;

            path {
                fill: $white;
                @include transition(fill);
            }
        }

        @include hocus {
            svg path {
                fill: transparentize($white, 0.5);
            }
        }
    }

    &__list {
        width: 100%;
        @include flex(space-evenly, flex-end, row, nowrap);

        body.ie &,
        body.edge & {
            justify-content: space-around;
        }

        @media #{$tabletHMQ} {
            position: fixed;

            top: $header-height-mobile;
            left: 0;

            height: calc(var(--vh, 1vh) * 100 - #{$header-height-mobile});

            @include flex(space-evenly, center, column, nowrap);

            background-color: $red;

            transform: translate3d(0, -100%, 0);

            @include transition(transform, $speed * 1.5, $easing, .3s);

            .is-menu-open & {
                transform: translate3d(0, 0, 0);
                @include transition(transform, $speed * 1.5, $easing, .3s);
            }
        }
    }

    &__item {
        @media #{$tabletHMQ} {
            opacity: 0;
            transform: translateY(10px) translateZ(0);

            transition: opacity $speed $easing,
                        transform $speed $easing;

            .is-menu-open & {
                opacity: 1;
                transform: translateY(0) translateZ(0);

                @for $i from 1 through 6 {
                    &:nth-child(#{$i}) {
                        $d: 0.4s + 0.07s * $i;
                        transition: opacity $speed $easing $d,
                                    transform $speed $easing ($d + 0.08s);
                    }
                }
            }
        }
    }

    &__link {
        position: relative;

        color: $white;
        letter-spacing: 0.3px;

        opacity: 1;
        @include transition(opacity);
        @include smooth;

        @include hocus {
            &:not(.is-active) {
                opacity: 0.5;
            }
        }

        @media #{$tabletHMQ} {
            @include font-size($font-size);
        }

        &::before {
            $s: $unit-small * 0.5;
            content: '';

            position: absolute;
            bottom: $s;
            left: $s;
            right: $s;

            height: 1px;

            background-color: $white;

            opacity: 0;
            @include transition(opacity);
        }

        &.is-active {
            &::before {
                opacity: 0.5;
            }
        }
    }

    &__switch {
        position: relative;
        @include flex(flex-start, center, row, nowrap);

        margin-left: auto;
        padding-left: 20px;

        @media #{$tabletHMQ} {
            position: fixed;
            top: 18px;
            right: 20px;

            transform: translateX(-50%);

            z-index: 1;

            pointer-events: none;
            opacity: 0;
            @include transition(opacity);

            .is-menu-open & {
                pointer-events: initial;
                opacity: 1;
                @include transition(opacity, $speed, $easing, 0.6s);
            }
        }
    }

    &__langs-trigger {
        $s: 40px;

        width: $s;
        height: $s;

        @media #{$tabletHMQ} {
            $s: 25px;

            width: $s;
            height: $s;
        }

        svg {
            width: 100%;
            height: 100%;

            path {
                fill: $white;
                @include transition(fill);

            }
        }

        @include hocus {
            svg path {
                fill: transparentize($white, 0.5)
            }
        }
    }

    &__langs {
        position: absolute;
        right: 0;
        display: inline-block;

        top: 150%;
        right: 20px;

        background-color: $white;
        border-radius: 5px;

        padding: ($unit-small * 0.6) 0;

        opacity: 0;
        transform: translate3d(50%, 10px, 0);
        transform-origin: center bottom;

        pointer-events: none;

        transition: opacity $speed $easing,
                    transform $speed $easing;

        @media #{$tabletHMQ} {
            right: 13px;
        }

        .is-langs-open-header & {
            opacity: 1;
            transform: translate3d(50%, 0, 0);

            pointer-events: initial;
        }

        &::before {
            content: '';

            position: absolute;
            top: -8px;
            left: 50%;
            margin-left: -8px;

            width: 0;
            height: 0;

            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 10px solid $white;

            z-index: -1;
        }
    }

    &__langs-item {
        width: 100%;
        display: inline-block;

        @include font-size(16px);
        color: $dark-grey;

        padding: ($unit-small * 0.4) ($unit * 0.5);

        background-color: transparent;

        transition: background-color $speed $easing,
                    color $speed $easing;

        &:hover {
            background-color: $dark-grey;
            color: $white;
        }
    }

    &__menu-trigger {
        position: absolute;
        top: 50%;
        right: $unit-small * 0.5;

        padding: $unit-small * 0.5;

        transform: translateY(-50%);

        width: 26px;
        height: 17px;

        z-index: 1;

        box-sizing: content-box;
    }

    &__burger {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        width: 100%;
        height: 100%;

        @include transition(opacity);

        &-line {
            width: 100%;
            height: 3px;

            background-color: $white;
            border-radius: 1.5px;

            transform: scaleX(1);
            transform-origin: 0% 50%;
            @include transition(transform, $speed, $easing, .3s);

            &:nth-child(2) {
                transition-delay: 0.4s;
            }
            &:nth-child(3) {
                transition-delay: 0.5s;
            }
        }

        .is-menu-open & {
            &-line {
                transform: scaleX(0);
                @include transition(transform, .2s);

                &:nth-child(1) {
                    transition-delay: 0.2s;
                }
                &:nth-child(2) {
                    transition-delay: 0.1s;
                }
            }
        }
    }

    &__cross {
        position: absolute;
        display: block;

        top: 12px;
        right: 14px;

        width: 17px;
        height: 17px;

        &:before, &:after {
            content: "";
            position: absolute;
            display: block;

            bottom: 0;
            left: 1px;

            width: 24px;
            height: 3px;

            background: $white;
            border-radius: 1.5px;

            transform-origin: center;
            backface-visibility: hidden;
            @include transition(transform);
        }
        &:before {
            transform-origin: left bottom;
            transform: rotate(-45deg) scaleX(0);
        }
        &:after {
            left: auto;
            right: 1px;
            transform-origin: right bottom;
            transform: rotate(45deg) scaleX(0);
            transition-delay: 0.2s;
        }

        .is-menu-open & {
            &:before, &:after {
                @include transition(transform, $speed, $easing, .4s);
            }
            &:before {
                transform: rotate(-45deg) scaleX(1);
            }
            &:after {
                transform: rotate(45deg) scaleX(1);
                transition-delay: 0.6s;
            }
        }
    }
}