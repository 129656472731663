@each $colorKey, $color in $colors {
    .u-#{$colorKey} {
        color: $color;
    }

    .u-bg-#{$colorKey} {
        background-color: $color;
    }
}

.u-gradient1 {
    background-image: $gradient1;
}

.u-gradient2 {
    background-image: $gradient2;
}

.u-text-gradient1 {
    color: $red;

    .desktop:not(.ie) & {
        background-image: $gradient1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.u-text-gradient2 {
    color: $red;
    background-image: $gradient2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}