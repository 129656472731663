/*
    Smooth Scroll Manager
    v2
*/
html, body {
    position: relative;
}

body {
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    &.locked {
        overflow-y: hidden;
    }

    &.desktop {
        overscroll-behavior-y: none;
    }
}

.scroll-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}