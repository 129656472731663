.pricing-page {
    .c-separator {
        padding: $unit 0;

        @media #{$tabletWMQ} {
            padding: ($unit * 0.5) 0;
        }
    }
    .c-fees {
        &__inner {
            margin: $unit 0;
        }

        &__figure {
            font-size: 24vh;
            line-height: 0.8;
        }
    }

    .c-simulator {

        &__wrapper {
            position: relative;
            padding: 10vh 0;

            @media #{$tabletWMQ} {
                padding: 0;
            }
        }

        &__bg-row {
            @include absoluteBox;
            pointer-events: none;
        }

        &__bg {
            @include center-align;

            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w}) {
                transition-delay: 1.4s;
            }

            @media #{$mobileMQ} {
                width: 260%;
            }
        }

        &__title {
            margin: $unit 0;

            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w}) {
                margin: $unit 0;
                transition-delay: 1.6s;
            }
        }

        &__form {
            position: relative;

            padding: $unit-small * 2;

            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w}) {
                transition-delay: 1.6s;
            }
        }

        &__input {
            position: relative;

            border: solid 1px $light-grey;
            padding-left: $unit * 0.5;

            &[disabled] {
                background-color: $light-grey;
            }
        }

        &__currency {
            position: absolute;

            top: 50%;
            left: $unit * 0.25;

            transform: translateY(-50%);

            width: $font-size;
            height: $font-size;

            pointer-events: none;
            user-select: none;

            &::before {
                content: '$';

                @extend .o-small-body;
                color: $dark-grey;

                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &__text {
            padding-top: $unit;
        }
    }
}