.o-arrow-link {
    @extend .o-h5;
    @include smooth;

    @include hocus {
        &:not(.is-active) {
            .o-arrow-link__icon svg {
                animation-name: move;
                animation-duration: $speed * 1.25;
                animation-timing-function: $SineEaseOut;
            }
        }
    }

    &__icon {
        position: relative;
        display: inline-block;

        width: 29px;
        height: 17px;

        overflow: hidden;

        svg {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }

    @keyframes move {
        0% {
            transform: translateX(0%);
        }
        50% {
            transform: translateX(120%);
        }
        51% {
            transform: translateX(-120%);
        }
        100% {
            transform: translateX(0%);
        }
    }

    &--dark-grey {
        color: $dark-grey;

        .o-arrow-link__icon svg path {
            fill: $dark-grey;
        }
    }

    &--red {
        color: $red;

        .o-arrow-link__icon svg path {
            fill: $red;
        }
    }
}