.blog-page, .single-page {
    .c-filters {
        &__title {
            opacity: 0.4;
        }

        &__btn {
            @extend .o-button;

            width: auto;

            font-family: $font-helvetica;
            @include font-size($font-size-footer);
            padding: ($unit-small * 0.5) $unit-small;

            border-radius: 4px;
            margin-top: $unit-small * 0.5;
            border-style: solid;
            border-width: 1px;
            border-color: $light-grey;

            transition: background-color $speed $easing,
                        border-color $speed $easing;

            background-color: $white;
            color: $dark-grey;

            @include hocus {
                background-color: $red;
                color: $white;
                border-color: $red;
            }

            &.is-active {
                background-color: $red;
                color: $white;
                border-color: $red;
            }
        }
    }

    .c-results {
        span {
            opacity: 0.4;
        }
    }

    .c-side-spacing {
        height: $unit-small * 1.5;
    }

    .c-search {
        &__form {
            position: relative;
        }
        &__input {
            @extend .o-small-body;

            font-family: $font-helvetica;
            font-weight: $weight-normal;

            padding: ($unit-small);

            border-style: solid;
            border-width: 1px;
            border-color: $light-grey;

            @include transition(border-color);

            @include hocus {
                border-color: $dark-grey;
            }

            @media #{$tabletWMQ} {
                @include font-size($font-size-small-body-mobile);
            }

            &::placeholder {
                color: transparentize($dark-grey, 0.4);
            }
        }
        &__submit {
            position: absolute;

            height: 100%;

            top: 50%;
            right: 0;

            transform: translateY(-50%);

            padding: 0 $unit-small;

            @include hocus {
                i {
                    transform: scale3d(1.1, 1.1, 1);
                }
            }

            i {
                display: block;
                width: 25px;
                height: 25px;

                transform: scale3d(1, 1, 1);
                @include transition(transform);

                svg {
                    width: 100%;
                    height: 100%;

                    path {
                        fill: $dark-grey;
                    }
                }
            }
        }
    }

    .c-trending {
        hr {
            border-color: $light-grey;
            margin: ($unit-small * 0.5) 0 $unit-small;
        }

        &__item {
            position: relative;
            line-height: 1;
            padding-left: 8px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;

                width: 2px;
                height: 2px;
                border-radius: 1px;
                background-color: $light-grey;
            }
        }

        &__link {
            @extend .o-footer;

            display: block;
            @include smooth-opacity;

            padding: ($unit-small * 0.5) 0;

            color: $dark-grey;
        }
    }

    .c-events {
        hr {
            border-color: $light-grey;
            margin: ($unit-small * 0.5) 0 $unit-small;
        }

        &__item {
            line-height: 1;
        }

        &__link {
            @extend .o-footer;

            display: flex;

            padding: ($unit-small * 0.75) 0;

            color: $dark-grey;

            @include hocus {
                .c-events__thumbnail img {
                    transform: scale3d(1.1, 1.1, 1);
                }

                .c-events__title {
                    color: darken($dark-grey, 15);
                }
            }
        }

        &__image {
            width: 45%;

            @media #{$tabletWMQ} {
                width: 35%;
            }

            @media #{$mobileMQ} {
                width: 40%;
            }
        }

        &__thumbnail {
            position: relative;
            top: 0;
            left: 0;
            border-radius: 5px;

            img {
                display: block;
                width: 100%;

                transform: scale3d(1.05, 1.05, 1);
                transform-origin: center center;
                @include transition(transform);
            }
        }

        &__infos {
            @include flex(space-around, flex-start, column, nowrap);
            width: 55%;

            padding-left: $unit-small * 0.75;

            @media #{$mobileMQ} {
                width: 60%;
                justify-content: space-between;
            }

            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w + 1}) {
                justify-content: center;
            }
        }

        &__title {
            @extend .o-small-body;
            line-height: 1.5;
            @include transition(color);

            @media only screen and (max-width:#{$tablet-h + 1}) {
                @include font-size($font-size-small-body-mobile * 0.9);
                line-height: 1.2;
            }
            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w + 1}) {
                @include font-size($font-size-small-body * 1.2);
            }
        }

        &__pin {
            display: block;

            $w: 13px;
            $h: 19px;
            $scale: 1;

            width: $w * $scale;
            height: $h * $scale;

            margin-right: $unit-small * 0.5;

            float: left;

            svg {
                width: 100%;
                height: 100%;
            }

            @media only screen and (max-width:#{$tablet-h + 1}) {
                $scale: 0.8;
                width: $w * $scale;
                height: $h * $scale;

                margin-right: $unit-small * 0.25;
            }

            @media #{$mobileMQ} {
                $scale: 0.7;
                width: $w * $scale;
                height: $h * $scale;
            }
        }

        &__location {
            @extend .o-footer;
            @include flex(flex-start, center, row, nowrap)

            @media only screen and (max-width:#{$tablet-h + 1}) {
                @include font-size($font-size-footer * 0.9);
            }
            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w + 1}) {
                padding: $unit-small 0;
                @include font-size($font-size-footer * 1.2);
            }
        }

        &__date {
            @extend .o-footer;
            opacity: 0.4;

            @media only screen and (max-width:#{$tablet-h + 1}) {
                @include font-size($font-size-footer * 0.9);
            }
            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w + 1}) {
                @include font-size($font-size-footer * 1.2);
            }
        }

        .o-arrow-link {
            display: inline-block;
            margin-top: $unit-small;
            @include font-size($font-size-small-body);

            i {
                vertical-align: middle;
            }
        }
    }

    .c-articles {
        padding-right: $unit-small * 1.5;

        @media #{$tabletHMQ} {
            padding-right: 0;
        }
    }

    .c-article {
        @include flex(flex-start, flex-start, row, nowrap);

        width: 100%;
        padding: $unit-small * 1.5;

        margin-bottom: $unit-small * 1.5;
        outline: none;

        @media #{$mobileMQ} {
            @include flex(flex-start, flex-start, column, nowrap);
            padding: $unit-small;
        }

        @include hocus {
            .c-article__thumbnail img {
                transform: scale3d(1.05, 1.05, 1);
            }

            .c-article__title {
                color: darken($dark-grey, 15);
            }
        }

        &__image {
            width: 30%;
            border-radius: 12px;
            overflow: hidden;

            @media #{$mobileMQ} {
                width: 100%;
            }
        }

        &__thumbnail {
            &::before {
                padding-bottom: 120%;

                @media #{$mobileMQ} {
                    padding-bottom: 75%;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                transform: scale3d(1, 1, 1);
                transform-origin: center center;
                @include transition(transform);
            }
        }

        &__infos {
            @include flex(space-between, flex-start, column, nowrap);
            width: 70%;
            align-self: stretch;
            height: auto;

            padding-left: $unit-small * 1.5;

            body.ie & {
                display: block;
            }

            @media #{$mobileMQ} {
                width: 100%;
                padding-left: 0;
                padding-top: $unit-small * 1.5;
            }
        }

        &__title {
            @include font-size($font-size-h4);
            font-weight: $weight-medium;
            line-height: 1.24;

            @include transition(color);

            @media #{$mobileMQ} {
                padding: ($unit-small * 0.75) 0;
            }

            @media only screen and (max-width:#{$tablet-h + 1}) {
                @include font-size($font-size-h4 * 0.75);
            }
        }

        &__excerpt {
            @extend .o-small-body;
            line-height: 1.5;

            body.ie & {
                padding: $unit-small 0;
            }
        }

        &__date {
            @extend .o-footer;
            opacity: 0.4;

            @media #{$mobileMQ} {
                padding-top: $unit-small * 0.5;
            }
        }
    }

    .c-pagination {
        margin-top: 10vh;

        @media #{$tabletHMQ} {
            margin-bottom: 10vh;
        }

        &__button {
            width: auto;
            border-radius: 7px;
            padding: ($unit-small * 0.25) ($unit-small * 0.5);

            margin-right: $unit-small * 2;

            @media #{$mobileMQ} {
                margin-right: $unit-small * 0.25;
            }

            &--next, &--prev {
                color: $dark-grey;
                font-weight: $weight-normal;

                i {
                    display: inline-block;
                    position: relative;
                    overflow: hidden;

                    width: 6px;
                    height: 9px;
                    padding: 0 3px;
                    box-sizing: content-box;

                    margin-bottom: 2px;
                    margin-left: $unit-small * 0.5;

                    svg {
                        @include absoluteBox;

                        path {
                            fill: $dark-grey;
                        }
                    }
                }

                @include hocus {
                    i svg {
                        animation-name: move;
                        animation-duration: $speed * 1.5;
                        animation-timing-function: $SineEaseOut;
                    }
                }
            }

            &--prev {
                i {
                    transform: rotate(180deg);
                }
            }
        }

    }

    .c-dropdown {
        &__select {
            @extend .o-small-body;
            width: 100%;
            color: transparentize($dark-grey, 0.4);
        }
    }
}