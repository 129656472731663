.c-footer {
    margin-top: $unit;
    overflow-x: hidden;

    &__decorator {
        position: relative;
        top: 1px;
        width: calc(100% + 4px);
        margin-left: -2px;

        svg {
            display: block;
            width: 100%;
            path {
                fill: $red;

            }
       }
    }

    &__bg {
        background-color: $red;
        overflow: hidden;
    }

    &__logo {
        display: inline-block;

        width: 201px;
        height: 35px;

        svg {
            width: 100%;
            height: 100%;

            path {
                fill: $white;
                @include transition(fill);
            }
        }

        @include hocus {
            svg path {
                fill: transparentize($white, 0.5);
            }
        }
    }

    &__primary {
        padding-bottom: $unit * 1.75;

        @media #{$tabletHMQ} {
            padding-bottom: $unit * 0.5;
        }
    }

    &__list {
        margin-left: -$unit-small * 0.5;
        @include flex(space-between, flex-end, row, nowrap);

        @media #{$tabletHMQ} {
            flex-wrap: wrap;
            padding-top: $unit-small * 2;
        }
    }

    &__link {
        flex-grow: 1;
        @extend .o-small-body;

        color: $white;
        letter-spacing: -0.67px;

        @include smooth-opacity;

        @media #{$tabletHMQ} {
            flex-basis: 50%;
        }

        span {
            position: relative;

            &::before {
                content: '';

                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                height: 1px;

                background-color: $white;

                opacity: 0;
                @include transition(opacity);
            }
        }


        &.is-active {
            span::before {
                opacity: 0.5;
            }
        }
    }

    &__newsletter {
        @include flex(flex-start, flex-start, column, nowrap);

        @media #{$tabletHMQ} {
            padding-top: $unit;
        }
    }

    &__form {
        @include flex(flex-start, flex-start, column, nowrap);
        width: 100%;
    }

    &__input {
        width: 100%;

        border: solid 1px $red;
    }

    &__submit {
        margin-left: auto;

        @extend .o-small-body;
        @include smooth;

        color: $white;
        letter-spacing: -0.67px;

        @include hocus {
            &:not(.is-active) {
                .c-footer__arrow svg {
                    animation-name: move;
                    animation-duration: $speed * 1.25;
                    animation-timing-function: $SineEaseOut;
                }
            }
        }
    }

    &__arrow {
        position: relative;
        display: inline-block;

        width: 29px;
        height: 17px;

        vertical-align: middle;
        overflow: hidden;

        svg {
            path {
                fill: $white;
            }
        }
    }

    &__hr {
        width: 100%;
        height: 1px;

        background-color: $white;
        opacity: 0.5;
    }

    &__secondary {
        padding-top: $unit-small * 2;
        padding-bottom: $unit;

        @media #{$tabletHMQ} {
            padding-top: $unit-small;
            padding-bottom: $unit-small * 2;
            flex-direction: column-reverse;
        }
    }

    &__list-secondary {
        @extend .c-footer__list;

        @media #{$tabletHMQ} {
            @include flex(flex-start, flex-start, column, nowrap);
            padding-top: 0;
        }
    }

    &__sublink {
        @extend .o-footer;

        color: $white;

        @include smooth-opacity;
        padding: $unit-small * 0.5;
        margin-left: -$unit-small * 0.5 + $gutter;
    }

    &__switch {
        position: relative;
        @include flex(flex-start, center, row, nowrap);

        margin-left: auto;
        padding-left: 20px;

        @media #{$tabletHMQ} {
            margin-left: initial;
            margin-right: auto;
            padding-left: $gutter;

            margin-bottom: $unit-small * 0.5;
        }
    }

    &__langs-trigger {
        $s: 40px;

        width: $s;
        height: $s;

        @media #{$tabletHMQ} {
            $s: 25px;

            width: $s;
            height: $s;
        }

        svg {
            width: 100%;
            height: 100%;

            path {
                fill: $white;
                @include transition(fill);

            }
        }

        @include hocus {
            svg path {
                fill: transparentize($white, 0.5)
            }
        }
    }

    &__langs {
        position: absolute;
        right: 0;
        display: inline-block;

        bottom: 150%;
        right: 20px;

        background-color: $white;
        border-radius: 5px;

        padding: ($unit-small * 0.6) 0;

        opacity: 0;
        transform: translate3d(50%, 10px, 0);
        transform-origin: center bottom;

        pointer-events: none;

        transition: opacity $speed $easing,
                    transform $speed $easing;

        @media #{$tabletHMQ} {
            right: 13px;
        }

        .is-langs-open-footer & {
            opacity: 1;
            transform: translate3d(50%, 0, 0);

            pointer-events: initial;
        }

        &::before {
            content: '';

            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -8px;

            width: 0;
            height: 0;

            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 10px solid $white;

            z-index: -1;
        }
    }

    &__current-lang {
        @include font-size(18px);
    }

    &__langs-item {
        width: 100%;
        display: inline-block;

        @include font-size(16px);
        color: $dark-grey;

        padding: ($unit-small * 0.4) ($unit * 0.5);

        background-color: transparent;

        transition: background-color $speed $easing,
                    color $speed $easing;

        &:hover {
            background-color: $dark-grey;
            color: $white;
        }
    }
}