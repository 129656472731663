.o-input {
    border-radius: 12px;
    background-color: $white;

    padding: ($unit-small * 0.75) ($unit * 0.5);

    opacity: 1;

    @extend .o-small-body;
    @include smooth;

    @include transition(background-color);
    transition: background-color $speed $easing,
                border $speed $easing;

    &::placeholder {
        color: transparentize($dark-grey, 0.4);
    }

    &:focus {
        border: solid 1px $dark-grey;
    }
}