.home-page {
    padding-bottom: 10vh;

    .mobile & {
        padding-bottom: 0;
    }

    .swiper-wrapper.row {
        .mobile & {
            flex-wrap: nowrap;
        }
    }

    .o-title-shift {
        padding-left: $unit-small * 2;

        @media #{$tabletWMQ} {
            padding-left: $unit-small;
        }
    }

    .o-h1 {
        @media only screen and (min-width:#{$tablet-h}) and (max-width:#{$desktop - 1}) {
            @include font-size($font-size-h1 * 0.75);
        }
    }

    .c-hero {
        position: relative;
        height: calc(100vh - #{$header-height});

        @include flex(center, flex-start, column, nowrap);

        @media #{$tabletHMQ} {
            height: 110vh;
        }

        @media #{$mobileMQ} {
            justify-content: flex-start;
            padding-top: $unit * 0.5;
        }

        @media #{$tinyCellMQ} {
            height: 120vh;
        }

        &__bg {
            @include absoluteBox;
            overflow: hidden;
            z-index: -1;

            &::before {
                content: '';
                @include absoluteBox;
                z-index: 1;
                background-color: transparentize(#000000, 0.9);
            }
        }
        &__video {
            position: absolute;
        }
        &__image {
            @include absoluteBox;
            z-index: -1;
            object-fit: cover;
            object-position: center;
        }
        &__wrapper {
            padding-bottom: 15%;
        }
        &__decorator {
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;

            svg {
                display: block;
                width: 100%;

                path {
                    fill: $white;
                }
            }
        }
        &__title {
            @media only screen and (max-width:#{$tablet-h + 1}) {
                @include font-size($font-size-h2-mobile * 1.5);
            }
            @media #{$tabletWMQ} {
                @include font-size($font-size-h2-mobile);
            }
            @media #{$mobileMQ} {
                line-height: 1.1;
            }
        }
        &__text {
            padding: $unit 0;

            @media only screen and (max-width:#{$tablet-h + 1}) {
                padding-top: $unit * 0.25;
            }

            @media #{$tabletHMQ} {
                padding: ($unit * 0.25) 0 ($unit * 0.75);
            }

            @media #{$mobileMQ} {
                line-height: 1.2;
            }
        }
        &__input {
            padding-top: $unit-small;
            padding-bottom: $unit-small;
            border: solid 1px $white;

            &:focus {
                border: solid 1px $red;
            }
        }
        &__input-error {
            position: absolute;
            top: -$unit-small * 0.25;
            right: 0;

            transform: translateY(-100%);
        }

        &__submit {
            @media #{$tabletHMQ} {
                margin-top: $unit * 0.5;
            }
        }
    }

    .c-app {
        position: relative;
        margin-top: -15%;

        @media #{$tabletWMQ} {
            margin-top: -30%;
        }

        @media #{$mobileMQ} {
            margin-top: -60%;
        }

        @media #{$tinyCellMQ} {
            margin-top: -40%;
        }

        &__row {
            @media #{$tabletWMQ} {
                justify-content: center;
            }
        }

        &__text {
            padding-bottom: 20%;
        }
    }

    .c-solutions {
        position: relative;

        &::before {
            content: '';
            @include absoluteBox;
            @extend .u-gradient2;
            opacity: 0.05;
            z-index: -1;
        }

        &__decorator {
            position: absolute;
            width: 100%;

            svg {
                display: block;
                width: 100%;
                path {
                    fill: $white;
                }
            }

            &--top {
                top: -2px;
            }
            &--bottom {
                bottom: 0;
            }
        }

        &__wrapper {
            position: relative;
            z-index: 1;

            p {
                opacity: 0.6;
            }

            .mobile & {
                padding-bottom: 10%;
            }
        }

        &__spacing {
            padding: $unit 0;

            @media #{$tabletWMQ} {
                padding: ($unit * 0.5) 0;
            }
        }

        &__inner {
            padding-bottom: 15%;

            .swiper-container {
                .mobile & {
                    width: 100vw;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }


            .swiper-pagination {
                bottom: 0;
            }
            .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
                margin: 0 7px;
            }
            .swiper-pagination-bullet-active {
                background: $red;
            }
        }

        &__item {
            @include flex(flex-start, flex-start, row, nowrap);
            align-self: stretch;
            height: initial;

            padding-right: $unit-small * 2;
            padding-bottom: $unit-small * 4;

            .mobile & {
                @include flex(flex-start, center, column, nowrap);
                width: 100%;

                padding-right: 0;
                padding-bottom: $unit-small * 2;
            }

            .o-ratio {
                flex: 1 0 35%;

                .mobile & {
                    width: 50%;
                }
            }

            img {
                width: 100%;
                height: auto;
                margin-top: -5%;
                transform: scale(1.1);

                .mobile & {
                    margin-top: 0;
                }
            }
        }

        &__item-wrapper {
            padding-left: $unit-small;

            .mobile & {
                padding-left: $unit-small * 1.5;
                padding-right: $unit-small * 1.5;
                padding-top: $unit * 0.5;

                @media #{$tinyCellMQ} {
                    padding-left: $unit-small;
                    padding-right: $unit-small;
                }
            }
        }

        &__item-title {
            position: relative;
            display: inline-block;

            span:first-child {
                display: inline-block;
                padding-bottom: 3px;
            }

            i {
                position: absolute;
                top: $font-size-subtitle * 0.5;
                right: -20px;
                width: 20px;
                height: 2px;
                background-color: $red;
                transform: translateX(100%);
            }
        }

        .o-arrow-link {
            margin-left: auto;

            @media #{$mobileMQ} {
                padding-top: $unit-small * 1.5;
                padding-bottom: $unit-small;
                margin-right: auto;
            }

            @media #{$tinyCellMQ} {
                padding-left: 0;
            }
        }
    }

    .c-events {
        .swiper-container {
            overflow: visible;

            .mobile & {
                width: 100vw;
                left: 50%;
                transform: translateX(-50%);
                overflow: hidden;
            }
        }
        &__item {
            &:nth-child(1) {
                padding-right: $unit * 0.5;
            }
            &:nth-child(2) {
                padding-left: $unit * 0.25;
                padding-right: $unit * 0.25;
            }
            &:nth-child(3) {
                padding-left: $unit * 0.5;
            }

            @include hocus {
                .c-events__thumbnail img {
                    transform: scale3d(1.05, 1.05, 1);
                }

                .c-events__title {
                    color: darken($dark-grey, 15);
                }
            }

            @media #{$tinyCellMQ} {
                padding-right: $unit-small;
            }

            .mobile & {
                padding: 0 $unit-small;
            }
        }
        &__thumbnail {
            position: relative;
            border-radius: 15px;

            img {
                display: block;
                width: 100%;

                transform: scale3d(1, 1, 1);
                transform-origin: center center;
                @include transition(transform);
            }
        }
        &__date {
            position: absolute;

            top: 0;
            top: 0;

            padding: $unit-small ($unit * 0.75);
            border-bottom-right-radius: 15px;

            z-index: 1;

            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w}) {
                padding: ($unit-small * 0.25) ($unit * 0.4);
            }
        }

        &__pin {
            display: inline-block;

            width: 13px;
            height: 19px;

            vertical-align: bottom;
        }

        &__title {
            @include transition(color);
        }

        .o-arrow-link {
            margin-left: auto;
            margin-right: $unit-small * 0.5;

            @media #{$mobileMQ} {
                margin-right: auto;
            }
        }
    }

    .c-money {
        position: relative;
        padding-bottom: 22%;

        margin-top: $unit;

        @media #{$tabletWMQ} {
            margin-top: 0;
        }

        &::before {
            content: '';
            @include absoluteBox;
            @extend .u-gradient2;
            opacity: 0.05;

            z-index: -1;
        }

        &__decorator {
            pointer-events: none;
            &--top {
                transform: scaleX(-1) scaleY(-1);
                position: relative;
                top: -2px;
            }
            &--bottom {
                position: absolute;

                bottom: -2px;
                right: 0;
                width: 100%;

                transform: scaleX(-1) scaleY(-1);
            }
            svg  {
                display: block;
                width: 100%;

                path {
                    fill: $white;
                }
            }
        }

        &__wrapper {
            @media #{$tabletHMQ} {
                margin: $unit 0;
            }
        }

        &__pig {
            position: absolute;
            top: 20%;
            right: 0%;
            z-index: 1;
            pointer-events: none;

            .desktop & {
                top: 10%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }

            @media #{$tabletHMQ} {
                position: relative;
                margin-top: $unit;
            }
        }

        &__text {
            position: relative;
            padding-left: $unit-small;

            &::before {
                content: '';
                position: absolute;

                top: 0;
                left: 0;

                width: 4px;
                height: 100%;

                background-color: $red;
            }
        }

        &__arrow-row {
            padding-left: $unit-small * 2;
        }
    }

    .c-booking {
        .o-ratio::before {
            padding-bottom: 100%;

            @media #{$tabletWMQ} {
                padding-bottom: 75%;
            }
        }
        &__wrapper {
            margin-top: $unit;
        }
        &__illu {
            position: absolute;

            top: 0;
            left: -12%;

            z-index: 1;
            overflow: hidden;

            @media #{$tabletHMQ} {
                position: relative;
                margin-top: $unit;
                left: 0;
            }

            @media #{$largeDesktopMQ} {
                width: 70%;
                left: -22%;
            }

            @media #{$xlargeDesktopMQ} {
                width: 75%;
                left: -32%;
            }
        }
        &__text {
            margin-top: $unit;

            @media #{$tabletWMQ} {
                margin-top: $unit * 0.5;
            }
        }
    }

    .c-eventsite {
        position: relative;
        margin-top: $unit;

        @media #{$tabletWMQ} {
            margin-top: 0;
        }

        &::before {
            content: '';
            @include absoluteBox;
            @extend .u-gradient2;
            opacity: 0.05;
            z-index: -1;
        }

        &__decorator {
            position: absolute;
            width: 100%;

            svg {
                display: block;
                width: 100%;
                path {
                    fill: $white;
                }
            }

            &--top {
                top: -2px;
            }
            &--bottom {
                bottom: 0;
            }
        }

        &__illu {
            position: absolute;

            top: 50%;
            right: 0;

            width: 65%;

            transform: translate(22%, -25%);

            @media #{$largeDesktopMQ} {
                width: 75%;
                transform: translate(33%, -25%);
            }

            @media #{$xlargeDesktopMQ} {
                width: 85%;
                transform: translate(55%, -25%);
            }

            @media #{$tabletWMQ} {
                position: relative;

                top: 0;
                left: 0;

                width: 100%;

                transform: none;
                margin-top: $unit;
            }
        }

        &__wrapper {
            position: relative;
            z-index: 1;
            padding-top: 30%;
            padding-bottom: 15%;

            @media #{$tabletWMQ} {
                padding-top: 35%;
                padding-bottom: 30%;
            }
        }

        h3 {
            padding-left: $unit-small * 2;
        }

        &__inner {
            padding-bottom: 15%;
        }
    }

    .c-control {
        &__decorator {
            margin: 0 auto;
            width: 150px;
            height: 2px;

            margin-bottom: $unit * 2;

            @media #{$tabletHMQ} {
                margin-bottom: $unit;
            }
        }

        &__item {
            img {
                width: 90px;
                height: 90px;
            }
        }

        &__item-title {
            @extend .o-subtitle;
            font-weight: $weight-normal;

            width: 70%;
            margin: 0 auto ($unit * 2);
            line-height: 1.2;

            @media #{$tabletHMQ} {
                @include font-size($font-size-small-body-mobile);
                width: 80%;
                margin-bottom: $unit;
            }
        }
    }

    .c-services {
        position: relative;
        padding-bottom: 35%;
        margin-top: $unit;

        @media #{$tabletWMQ} {
            padding-bottom: 30%;
            margin-top: 0;
        }

        &::before {
            content: '';
            @include absoluteBox;
            @extend .u-gradient2;
            opacity: 0.05;

            z-index: -1;
        }

        &__decorator {
            pointer-events: none;
            z-index: -1;

            &--top {
                transform: scaleX(-1) scaleY(-1);
                position: relative;
                top: -2px;
            }
            &--bottom {
                position: absolute;

                bottom: -2px;
                right: 0;
                width: 100%;

                transform: scaleX(-1) scaleY(-1);
            }
            svg  {
                display: block;
                width: 100%;

                path {
                    fill: $white;
                }
            }
        }

        &__wrapper {
            padding-top: 5%;
        }

        &__illu {
            position: absolute;

            top: 5%;
            left: 0;

            z-index: 1;
            pointer-events: none;

            transform: translateX(-5%);

            @media #{$tabletWMQ} {
                position: relative;

                top: 0;
                transform: none;

                margin-top: $unit;
            }
        }

        &__text {
            position: relative;
            padding-left: $unit-small * 2;

            &::before {
                content: '';
                position: absolute;

                top: 0;
                left: 0;

                width: 4px;
                height: 100%;

                background-color: $red;
            }
        }
    }

    .c-transactions {

        &__illu {
            position: absolute;

            top: 15%;
            right: -10%;

            z-index: 1;

            .desktop & {
                top: 0%;
            }

            @media #{$tabletWMQ} {
                @include hidden;
            }
        }
        &__phone {
            position: absolute;

            top: 15%;
            right: 10%;

            z-index: 1;

            @include col('width', 3.5, $num_cols);

            .desktop & {
                top: -15%;
            }

            .ie & {
                @include colIE('width', 3.5, $num_cols);
            }

            @media #{$tabletWMQ} {
                position: relative;

                top: 0;
                right: 0;

                margin: $unit auto 0;

                @include col('width', 8, $num_cols);

                .ie & {
                    @include colIE('width', 8, $num_cols);
                }
            }
        }
        &__buttons {
            padding-left: $unit-small * 2;

            @media #{$tabletWMQ} {
                padding-left: 0;
            }
        }

        &__button {
            width: 170px;
            box-sizing: content-box;

            @media #{$tabletWMQ} {
                width: 30%;
            }

            @media #{$mobileMQ} {
                width: 40%;
            }

            &:first-child {
                padding-right: $unit-small;

                @media #{$tabletWMQ} {
                    padding-right: $unit-small * 0.5;
                }
            }
        }
    }

    .c-clients {
        margin-top: $unit * 3;
        padding-bottom: 5vh;

        @media #{$tabletWMQ} {
            margin-top: $unit * 1.5;
        }

        .swiper-container {
            overflow: visible;

            .mobile & {
                width: 100vw;
                left: 50%;
                transform: translateX(-50%);
                overflow: hidden;
            }
        }

        &__item-col {
            display: flex;
            align-self: stretch;
            height: auto;

            padding-bottom: $unit-small;

            &:nth-child(1) {
                padding-right: $unit-small;
            }
            &:nth-child(2) {
                padding-left: $unit-small * 0.5;
                padding-right: $unit-small * 0.5;
            }
            &:nth-child(3) {
                padding-left: $unit-small;
            }

            @media #{$tabletWMQ} {
                &:nth-child(1) {
                    padding-right: $unit-small * 0.5;
                }
                &:nth-child(2) {
                    padding-left: $unit-small * 0.25;
                    padding-right: $unit-small * 0.25;
                }
                &:nth-child(3) {
                    padding-left: $unit-small * 0.5;
                    padding-left: $unit-small * 0.5;
                }
            }
            .mobile & {
                padding-left: $unit-small;
                padding-right: $unit-small * 0.5;
            }
        }
        &__item {
            display: flex;
            flex-flow: column nowrap;

            border-radius: 15px;
            border: solid 1px $light-grey;
            box-shadow: -4px 6px 13px 0 rgba(0,0,0,0.12);

            .mobile & {
                box-shadow: -2px 4px 8px 0 rgba(0,0,0,0.12);
            }

            overflow: hidden;
        }
        &__item-body {
            background-color: $white;
            padding: $unit ($unit-small * 2) ($unit * 1.5);

            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w}) {
                padding: ($unit * 0.75) ($unit-small * 1.5) $unit;
            }

            @media #{$mobileMQ} {
                padding: $unit ($unit-small * 2) $unit;
            }

            @media #{$tinyCellMQ} {
                padding: $unit-small $unit-small $unit;
            }
        }
        &__item-footer {
            position: relative;
            margin-top: auto;

            padding-top: $unit;
            padding-bottom: $unit-small * 2;
            background-color: $red;

            @media #{$tinyCellMQ} {
                padding-top: $unit * 0.8;
                padding-bottom: $unit-small;
            }

            .o-subtitle {
                margin-top: $unit-small * 0.75;
                margin-bottom: $unit-small * 0.25;

                @media #{$tabletWMQ} {
                    margin-top: 0;
                }
            }
        }
        &__item-portrait {
            position: absolute;

            top: 0;
            left: 50%;

            width: $unit * 1.75;
            height: $unit * 1.75;

            transform: translate(-50%, -50%);

            border-radius: 50%;

            @media #{$tabletWMQ} {
                width: $unit * 1.5;
                height: $unit * 1.5;
            }

            @media #{$tinyCellMQ} {
                width: $unit * 1.25;
                height: $unit * 1.25;
            }
        }
        &__name {
            @extend .o-subtitle;

            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w}) {
                @include font-size($font-size-subtitle * 0.9);
            }
        }
        &__job {
            @include font-size($font-size-small-body);
            font-weight: $weight-normal;

            @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w}) {
                @include font-size($font-size-small-body-mobile * 0.9);
            }
            @media only screen and (max-width:#{$mobile}) {
                @include font-size($font-size-small-body-mobile);
            }
        }
    }
}