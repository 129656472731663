.o-button {
    width: 100%;
    padding: $unit-small 0;
    border-radius: 12px;

    @include font-size($font-size-button);
    font-family: $font-roboto;
    font-weight: $weight-medium;
    color: $white;

    @include transition(background-color);

    @media #{$mobileMQ} {
        @include font-size($font-size-button-mobile);
    }

    &:disabled {
        pointer-events: none;
    }

    &--red {
        background-color: $red;

        @include hocus {
            background-color: $dark-red;
        }
    }

    &--dark-red {
        background-color: $dark-red;

        @include hocus {
            background-color: $red;
        }
    }

    &--grey {
        background-color: $light-grey;

        @include hocus {
            background-color: $red;
        }

        &.is-active {
            background-color: $red;
        }
    }

    &--white {
        background-color: $white;
        color: $dark-grey;

        &:not([disabled]) {
            @include hocus {
                background-color: $dark-red;
                color: $white;
            }
        }

        &.is-active {
            background-color: $dark-red;
            color: $white;
        }
    }
}