@include font-face(
    $font-helvetica,
    "assets/fonts/Helvetica/HelveticaNeue"
) {
    font-style:  normal;
    font-weight: 400;
}

// ==========================================================================
// Base / Headings
// ==========================================================================

@mixin h {
    font-family: $font-roboto;
    line-height: 1;
}

//
// Provide a generic class to apply common heading styles.
//
// @example
//   <p class="u-h"></p>
//
//
.o-h {
    @include h;
}

//
// Styles for headings 1 through 6 with classes to provide
// a double stranded heading hierarchy, e.g. we semantically
// need an H2, but we want it to be sized like an H1:
//
// @example
//   <h2 class="o-h1"></h2>
//
//
h1, .o-h1 {
    @extend .o-h;

    @include font-size($font-size-h1);
    font-weight: $weight-black;

    @media #{$tabletWMQ} {
        @include font-size($font-size-h1-mobile);
    }
}

h2, .o-h2 {
    @extend .o-h;

    @include font-size($font-size-h2);
    font-weight: $weight-black;
    line-height: 1.2;
    letter-spacing: 0.02px;

    @media #{$tabletWMQ} {
        @include font-size($font-size-h2-mobile);
    }
}

h3, .o-h3 {
    @extend .o-h;

    @include font-size($font-size-h3);
    font-weight: $weight-medium;

    @media #{$tabletWMQ} {
        @include font-size($font-size-h3-mobile);
    }
}

h4, .o-h4 {
    @extend .o-h;

    @include font-size($font-size-h4);
    font-weight: $weight-medium;

    line-height: 1.24;

    @media #{$tabletWMQ} {
        @include font-size($font-size-h4-mobile);
    }
}

h5, .o-h5 {
    @extend .o-h;

    @include font-size($font-size-h5);
    font-weight: $weight-medium;

    @media #{$tabletWMQ} {
        @include font-size($font-size-h5-mobile);
    }
}

.o-subtitle {
    @extend .o-h;

    @include font-size($font-size-subtitle);
    font-weight: $weight-medium;
}

.o-subtitle-reg {
    @extend .o-subtitle;

    font-weight: $weight-normal;
}

.o-body {
    @include font-size($font-size);
    font-weight: $weight-normal;
}

.o-small-body {
    @include font-size($font-size-small-body);
    font-weight: $weight-normal;

    @media #{$tabletWMQ} {
        @include font-size($font-size-small-body-mobile);
    }
}

.o-footer {
    @include font-size($font-size-footer);
    font-weight: $weight-normal;
}