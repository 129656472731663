.o-text-decorator {
    position: relative;
    padding-left: $unit-small * 2;

    &::before {
        content: '';
        position: absolute;

        top: 0;
        left: 0;

        width: 4px;
        height: 100%;

        background-color: $red;
        background-image: linear-gradient(to bottom, #e83e6e, #ea8c6f);
    }
}