/*a:link {-webkit-tap-highlight-color: #fcd700;}*/

html {
    font-size: $base-font-size;
}

body {
    font-family: $font-helvetica;
    @include font-size($font-size);
    line-height: $line-height;

    background-color: $white;
    color: $dark-grey;

    @media #{$tabletWMQ} {
        @include font-size($font-size-mobile);
        line-height: $line-height-mobile;
    }
}

::selection,
::-moz-selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

// a {
//     color: $link-color;

//     @include hocus {
//         color: $link-hover-color;
//     }
// }

.page {
    overflow-x: hidden;
    padding-top: $header-height;

    @media #{$tabletHMQ} {
        padding-top: $header-height-mobile;
    }

    p {
        @include smooth;
    }
}

.wrapper {
    $s: $unit-small * 1.5;
    @media only screen and (max-width:#{$container + $s}) {
        padding: 0 $s;
    }
    @media #{$tinyCellMQ} {
        padding: 0 $unit-small;
    }
}