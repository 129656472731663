// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #ffffff;

$dark-red: #b71730;
$red: #dd0c39;

$grey: #a6a5af;
$dark-grey: #46556d;
$light-grey: #e4e2f0;

$colors: (
    'white': $white,
    'dark-red': $dark-red,
    'red': $red,
    'grey': $grey,
    'dark-grey': $dark-grey,
    'light-grey': $light-grey
);

$gradient1: linear-gradient(to right, #ea8c6f, #e83e6e);
$gradient2: linear-gradient(to right, #fec700, #e70971);


// Specific
// =============================================================================

// Link
$link-color:       #1A0DAB;
$link-hover-color: darken($link-color, 10%);

// Selection
$selection-text-color:       #3297FD;
$selection-background-color: #fff;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;