.u-border {
    border: solid 1px $light-grey;
}

.u-rounded-box {
    @extend .u-border;
    border-radius: 14px;
}

.u-shadow {
    box-shadow: -4px 6px 13px 0 rgba(0, 0, 0, 0.12);
}