.terms-page {
    padding-bottom: 10vh;

    .c-hero {
        padding-top: 20vh;

        @media #{$tabletWMQ} {
            padding-top: 10vh;
        }

        &__inner {
            @include flex(center, center, colum, nowrap);
            position: relative;
            padding: 13vh 0;
            margin-bottom: $unit;

            @media #{$tabletWMQ} {
                margin-bottom: $unit-small;
            }

            img {
                @include absoluteBox;
                top: -5%;
            }
        }

        &__decorator {
            margin: 0 auto;
            width: 150px;
            height: 2px;
        }
    }

    h2 {
        margin: $unit 0;
    }
    h3 {
        margin: $unit-small 0;
    }
}