// ==========================================================================
// Tools / Mixins
// ==========================================================================

@mixin font-size($size) {
    font-size: $size;
    font-size: rem($size);
}

//
// Vertically-center the direct descendants of the current element.
//
// Centering is achieved by displaying children as inline-blocks. Any whitespace
// between elements is nullified by redefining the font size of the container
// and its children.
//
// @output `font-size`, `display`, `vertical-align`
//
@mixin o-vertical-center {
    font-size: 0;

    &::before {
        display: inline-block;
        height: 100%;
        content: "";
        vertical-align: middle;
    }

    > * {
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
    }
}

//
// Generate `:hover` and `:focus` styles in one go.
//
// @link    https://github.com/inuitcss/inuitcss/blob/master/tools/_tools.mixins.scss
// @content Wrapped in `:focus` and `:hover` pseudo-classes.
// @output  Wraps the given content in `:focus` and `:hover` pseudo-classes.
//
@mixin hocus {
    &:focus,
    &:hover {
        @content;
    }
}

//
// Generate `:active` and `:focus` styles in one go.
//
// @see     {Mixin} u-hocus
// @content Wrapped in `:focus` and `:active` pseudo-classes.
// @output  Wraps the given content in `:focus` and `:hover` pseudo-classes.
//
@mixin actus {
    &:focus,
    &:active {
        @content;
    }
}

//
// Prevent text from wrapping onto multiple lines for the current element.
//
// An ellipsis is appended to the end of the line.
//
// 1. Ensure that the node has a maximum width after which truncation can occur.
// 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor nodes.
//
// @param  {Number} $width [100%] - The maximum width of element.
// @output `max-width`, `word-wrap`, `white-space`, `overflow`, `text-overflow`
//
@mixin truncate($width: 100%) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;  // [2]
    @if $width {
        max-width: $width; // [1]
    }
}

//
// Applies accessible hiding to the current element.
//
// @output Properties for removing the element from the document flow.
//
@mixin accessibly-hidden() {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
}

//
// Allows an accessibly hidden element to be focusable via keyboard navigation.
//
// @content For styling the now visible element.
// @output  Injects `:focus`, `:active` pseudo-classes.
//
@mixin accessibly-focusable {
    @include actus {
        clip: auto;
        width: auto;
        height: auto;

        @content;
    }
}

//
// Hide the current element from all.
//
// The element will be hidden from screen readers and removed from the document flow.
//
// @link   http://juicystudio.com/article/screen-readers-display-none.php
// @output `display`, `visibility`
//
@mixin hidden($important: null) {
    display: none $important;
    visibility: hidden $important;
}

//
// Show the current element for all.
//
// The element will be accessible from screen readers and visible in the document flow.
//
// @param  {String}  $display   [block] - The rendering box used for the element.
// @output `display`, `visibility`
//
@mixin shown($display: block, $important: null) {
    display: $display $important;
    visibility: visible $important;
}

$viewportHeight: calc(var(--vh, 1vh) * 100);

@mixin fullHeight () {
    height: 100vh;
    height: $viewportHeight;
}