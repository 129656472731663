
.four-o-four-page {
    .c-hero {
        padding-top: 20vh;

        @media #{$tabletWMQ} {
            padding-top: 30vh;
        }

        &__inner {
            position: relative;
            @include center-align;

            h1 {
                font-size: 38vh;
                white-space: nowrap;

                @media #{$tabletWMQ} {
                    font-size: 28vh;
                }
            }
        }
    }
}