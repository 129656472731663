// already share style with blog

.single-page {
    .c-separator {
        padding: $unit 0;

        @media #{$tabletWMQ} {
            padding: ($unit * 0.5) 0;
        }
    }
    .c-hero {
        margin-bottom: $unit-small * 2.5;

        &__title {
            @include font-size($font-size-h2);
            font-weight: $weight-black;
            line-height: 1.2;
            letter-spacing: 0.02px;

            @media only screen and (max-width:#{$tablet-h + 1}) {
                @include font-size($font-size-h2-mobile * 1.25);
            }
            @media #{$tabletWMQ} {
                @include font-size($font-size-h2-mobile);
            }
        }

        &__infos {
            @include flex(flex-start, center, row, nowrap);
            padding-right: $unit-small * 1.5;

            @media #{$tabletWMQ} {
                padding-right: 0;
                margin-top: $unit-small;
            }
        }
        &__date {
            @extend .o-footer;
            opacity: 0.4;
        }
        &__list {
            margin-left: auto;

            li {
                display: inline-block;
                font-size: 0;
            }
        }
        &__button {
            display: block;
            position: relative;
            padding: $unit-small * 0.25;

            @include hocus {
                svg path {
                    fill: $red;
                }
            }

            svg {
                width: 16px;
                height: 16px;

                pointer-events: none;

                path {
                    fill: $dark-grey;
                    @include transition(fill);
                }
            }

            &--clipboard {
                position: relative;

                &::after {
                    content: attr(data-text);
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    font-size: 12px;

                    transform: translate3d(-50%, 110%, 0);
                    opacity: 0;

                    transition: transform $speed $easing,
                                opacity $speed $easing;

                    white-space: nowrap;
                    background-color: $dark-grey;
                    color: $white;
                    border-radius: 20px;
                    padding: 5px 10px;

                    @media #{$tabletWMQ} {
                        left: initial;
                        right: 0;
                        transform: translate3d(0%, 110%, 0);
                    }
                }

                &.is-active {
                    &::after {
                        opacity: 1;
                        transform: translate3d(-50%, 100%, 0);

                        @media #{$tabletWMQ} {
                            transform: translate3d(0%, 100%, 0);
                        }
                    }
                }
            }
        }
    }

    .c-content {
        padding-right: $unit-small * 1.5;
        padding-bottom: $unit;

        @media #{$tabletWMQ} {
            padding-right: 0;
        }

        img {
            display: block;
            width: 100%;
            border-radius: 5px;
            margin-bottom: $unit;
        }

        a {
            color: $red;
            @include smooth-opacity;
        }

        h1, h2, h3, h4, h5, h6 {
            margin: ($unit * 0.5) 0;
        }

        br {
            line-height: 2;
        }

        ul, ol {
            list-style-position: inside;
        }
    }
}