.loader {
    position: fixed;
    top: $header-height - 2px;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: $red;

    z-index: $zIndex-max + 1;

    @media #{$tabletHMQ} {
        top: $header-height-mobile - 2px;
    }
}