/*
 * VARS
 */

$gutter: 8px;
$container: 1240px;

$num_cols: 12;


/*
 * WRAPPER
 */
.wrapper {
	width: 100%;
	max-width: $container;
	margin: 0 auto;
	padding: 0 $gutter;

	&--full {
		max-width: none;
		padding: 0;
	}
}


/*
 * ROW
 */
.row {
	margin-left: -$gutter/2;
	margin-right: -$gutter/2;
	@include flex(flex-start, flex-start, "", wrap);

	&--h-center {
		justify-content: center;
	}
	&--h-end {
		justify-content: flex-end;
	}
	&--h-around {
		justify-content: space-around;
	}
	&--h-between {
		justify-content: space-between;
	}

	&--v-center {
		align-items: center;
	}
	&--v-end {
		align-items: flex-end;
	}
	&--v-stretch {
		align-items: stretch;
	}

	&--center {
		justify-content: center;
		align-items: center;
	}

	&--column {
		flex-direction: column;
	}

	&--nowrap {
		flex-wrap: nowrap;
	}
}


/*
 * COLS
 */
@for $i from 1 through $num_cols {
	.col-#{$i} {
		padding-left: $gutter/2;
		padding-right: $gutter/2;
		@include col('width', $i, $num_cols);

		.ie & {
			@include colIE('width', $i, $num_cols);
		}
	}

	.offset-#{$i} {
		@include col('margin-left', $i, $num_cols);

		.ie & {
			@include colIE('margin-left', $i, $num_cols);
		}
	}
}


// RESPONSIVE
@media screen and (max-width: $desktop) {
	@for $i from 1 through $num_cols {
		.col-desktop-#{$i} {
			@include col('width', $i, $num_cols);

			.ie & {
				@include colIE('width', $i, $num_cols);
			}
		}

		.offset-desktop-#{$i} {
			@include col('margin-left', $i, $num_cols);

			.ie & {
				@include colIE('margin-left', $i, $num_cols);
			}
		}
	}

	.offset-desktop-0 {
		margin-left: 0;
	}
}
@media screen and (max-width: $tablet-h) {
	@for $i from 1 through $num_cols {
		.col-tabletH-#{$i} {
			@include col('width', $i, $num_cols);

			.ie & {
				@include colIE('width', $i, $num_cols);
			}
		}

		.offset-tabletH-#{$i} {
			@include col('margin-left', $i, $num_cols);

			.ie & {
				@include colIE('margin-left', $i, $num_cols);
			}
		}
	}

	.offset-tabletH-0 {
		margin-left: 0;
	}
}
@media screen and (max-width: $tablet-w) {
	@for $i from 1 through $num_cols {
		.col-tabletW-#{$i} {
			@include col('width', $i, $num_cols);

			.ie & {
				@include colIE('width', $i, $num_cols);
			}
		}

		.offset-tabletW-#{$i} {
			@include col('margin-left', $i, $num_cols);

			.ie & {
				@include colIE('margin-left', $i, $num_cols);
			}
		}
	}

	.offset-tabletW-0 {
		margin-left: 0;
	}
}
@media screen and (max-width: $mobile) {
	@for $i from 1 through $num_cols {
		.col-mobile-#{$i} {
			@include col('width', $i, $num_cols);

			.ie & {
				@include colIE('width', $i, $num_cols);
			}
		}

		.offset-mobile-#{$i} {
			@include col('margin-left', $i, $num_cols);

			.ie & {
				@include colIE('margin-left', $i, $num_cols);
			}
		}
	}

	.offset-mobile-0 {
		margin-left: 0;
	}
}