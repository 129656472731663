@function em($size, $base: $font-size) {
    @if (type-of($size) == number) {
        @if (unit($size) != "px") {
            @error "`#{$size}` needs to be a pixel value.";
        }
    } @else {
        @error "`#{$size}` needs to be a number.";
    }

    @if (type-of($base) == number) {
        @if (unit($base) != "px") {
            @error "`#{$base}` needs to be a pixel value.";
        }
    } @else {
        @error "`#{$base}` needs to be a number.";
    }

    @return ($size / $base) * 1em;
}

@function rem($size, $base: $base-font-size) {
    @if (type-of($size) == number) {
        @if (unit($size) != "px") {
            @error "`#{$size}` needs to be a pixel value.";
        }
    } @else {
        @error "`#{$size}` needs to be a number.";
    }

    @if (type-of($base) == number) {
        @if (unit($base) != "px") {
            @error "`#{$base}` needs to be a pixel value.";
        }
    } @else {
        @error "`#{$base}` needs to be a number.";
    }

    @return ($size / $base) * 1rem;
}