// Transitions
// =============================================================================
$speed:  0.3s;
$easing: $Power2EaseOut;

@mixin transition($property, $speed: $speed, $easing: $easing, $delay: 0s) {
    transition: $property $speed $easing $delay;
}

@mixin smooth-opacity() {
    @include smooth;

    opacity: 1;
    @include transition(opacity);

    @include hocus {
        &:not(.is-active) {
            opacity: 0.5;
        }
    }
}

.u-smooth-opacity {
    @include smooth-opacity;
}

.js-scroll-anim {
    @for $i from 0 through 20 {
        $d: $i * 10;
        &.u-anim-delay-#{$d} {
            transition-delay: ($d * 10) * 1ms;
        }
        body.mobile-device &.u-anim-delay-mobile-#{$d} {
            transition-delay: ($d * 10) * 1ms;
        }
    }

    .o-text-decorator::before {
        transform: scaleY(0) translateZ(0);
        transform-origin: 50% 0%;
        transition: transform $speed $Power1EaseOut 500ms;
    }
    &.is-active {
        .o-text-decorator::before {
            transform: scaleY(1) translateZ(0);
        }
    }

    body.mobile-device &.js-anim-desktop,
    body.desktop &.js-anim-mobile {
        transition-delay: 0s !important;
    }
}
.u-anim-fade {
    opacity: 0;

    transition: opacity ($speed * 2.5) $SineEaseOut;

    &.is-active {
        opacity: 1;
    }
}
.u-anim-fade-translate {
    opacity: 0;
    transform: translateY(20%);

    transition: opacity ($speed * 2.5) $SineEaseOut,
                transform ($speed * 2) $SineEaseOut;

    &.is-active {
        opacity: 1;
        transform: translateY(0%);
    }
}
.u-anim-scaleX {
    transform: scaleX(0) translateZ(0);

    transition: transform $speed $SineEaseOut;

    &.is-active {
        transform: scaleX(1) translateZ(0);
    }
}