// ==========================================================================
// Utilities / Widths
// ==========================================================================

.is-visible {
    visibility: visible !important;
    opacity: 1 !important;
}

.is-invisible {
    visibility: hidden !important;
    opacity: 0 !important;
}

.is-hidden {
    @include hidden;
}

.is-hidden-mobile {
    @media #{$mobileMQ} {
        @include hidden(!important);
    }
}

.is-hidden-tablet-h {
    @media #{$tabletHMQ} {
        @include hidden(!important);
    }
}
.is-visible-tablet-h {
    @include hidden(!important);
    @media #{$tabletHMQ} {
        @include shown(block, !important);
    }
}

.is-hidden-tablet-w {
    @media #{$tabletWMQ} {
        @include hidden(!important);
    }
}

.is-hidden-desktop {
    @media #{$minDesktopMQ} {
        @include hidden(!important);
    }
}

.is-hidden-mobile-device {
    .mobile-device & {
        @include hidden(!important);
    }
}

.is-visible-mobile-device {
    @include hidden(!important);
    .mobile-device & {
        @include shown(block, !important);
    }
}

.is-hidden-desktop-device {
    .desktop & {
        @include hidden(!important);
    }
}

.is-hidden-cell {
    .mobile & {
        @include hidden(!important);
    }
}

.is-visible-cell {
    @include hidden;
    .mobile & {
        @include shown(block, !important);
    }
}