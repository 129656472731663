.o-generic-hero {
    position: relative;
    padding-bottom: 30vh;

    &::before {
        content: '';
        @include absoluteBox;
        @extend .u-gradient2;
        opacity: 0.05;
        z-index: -1;
    }

    &__spacing {
        padding: $unit 0;

        @media #{$tabletWMQ} {
            padding: ($unit * 0.5) 0;
        }
    }

    h1 {
        line-height: 1.1;
    }

    &__text {
        @media only screen and (max-width:#{$tablet-h + 1}) {
            @include col('width', 8, $num_cols);

            .ie & {
                @include colIE('width', 8, $num_cols);
            }
        }
        @media #{$mobileMQ} {
            @include col('width', 10, $num_cols);

            .ie & {
                @include colIE('width', 10, $num_cols);
            }
        }
    }

    &__decorator {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        svg {
            display: block;
            width: 100%;

            path {
                fill: $white;
            }
       }
    }

    & + .wrapper {
        position: relative;
        top: -25vh;
        margin-bottom: -15vh;
    }
}