// ==========================================================================
// Objects / Ratio
// ==========================================================================

/**
 * Create ratio-bound content blocks, to keep media (e.g. images, videos) in
 * their correct aspect ratios.
 *
 * http://alistapart.com/article/creating-intrinsic-ratios-for-video
 *
 * 1. Default cropping is a 1:1 ratio (i.e. a perfect square).
 */
 .o-ratio {
    position: relative;
    display: block;
    overflow: hidden;

    &:before {
        display: block;
        padding-bottom: 100%; /* [1] */
        width: 100%;
        content: "";
    }
}

.o-ratio__content,
.o-ratio > img,
.o-ratio > iframe,
.o-ratio > embed,
.o-ratio > object {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 100%;
}

$aspect-ratios: (
    (2:1),
    (4:3),
    (16:9),
) !default;

//
// Generate a series of ratio classes to be used like so:
//
// @example
//   <div class="o-ratio o-ratio--16:9">
//
//
@each $ratio in $aspect-ratios {
    @each $antecedent, $consequent in $ratio {
        @if (type-of($antecedent) != number) {
            @error "`#{$antecedent}` needs to be a number."
        }

        @if (type-of($consequent) != number) {
            @error "`#{$consequent}` needs to be a number."
        }

        .o-ratio--#{$antecedent}\:#{$consequent}::before {
            padding-bottom: ($consequent/$antecedent) * 100%;
        }
    }
}