/*
 * Responsive
 */

$xlarge: 1680px;
$large: 1440px;
$desktop: 1280px;
$tablet-h: 1023px;
$tablet-w: 768px;
$mobile: 480px;
$tinyCell: 374px;


/* -----------------------*/
/* [MED] ==== Media Queries
/*------------------------*/
$tinyCellMQ: "only screen and (max-width:#{$tinyCell})";
$mobileMQ: "only screen and (max-width:#{$mobile})";
$tabletHMQ: "only screen and (max-width:#{$tablet-h})";
$tabletWMQ: "only screen and (max-width:#{$tablet-w})";
$desktopMQ : "only screen and (max-width:#{$desktop})";

$minDesktopMQ : "only screen and (min-width:#{$tablet-h + 1})";
$midDesktopMQ : "only screen and (min-width:#{$desktop + 1})";
$largeDesktopMQ : "only screen and (min-width:#{$large})";
$xlargeDesktopMQ : "only screen and (min-width:#{$xlarge})";