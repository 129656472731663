.u-overflow-v {
    overflow: visible !important;
}
.u-overflow-x-v {
    overflow-x: visible !important;
}
.u-overflow-y-v {
    overflow-y: visible !important;
}
.u-overflow-h {
    overflow: hidden !important;
}
.u-overflow-x-h {
    overflow-x: hidden !important;
}
.u-overflow-y-h {
    overflow-y: hidden !important;
}