.dual-generic-page {
    .o-container {
        padding: $unit-small * 2;

        @media #{$mobileMQ} {
            padding: $unit-small;
        }
    }

    .o-contact-solutions {
        margin-top: -15vh !important;

        &__link {
            padding-top: $unit;

            @media #{$tabletWMQ} {
                padding-top: 0;
            }
        }
    }
}