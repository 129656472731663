.o-tabs {
    position: relative;
    @include flex(flex-start, flex-start, row, nowrap);
    overflow: hidden;
}

.o-tab {
    flex: 1 0 100%;
    padding: 0 1px;

    @include transition(transform);

    &--first {
        transform: translate3d(-100%, 0, 0);

        &.is-active {
            transform: translate3d(0, 0, 0);
        }
    }

    &--second {
        transform: translate3d(0, 0, 0);

        &.is-active {
            transform: translate3d(-100%, 0, 0);
        }
    }
}