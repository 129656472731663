.contact-page {
    .c-separator {
        padding: $unit 0;

        @media #{$tabletWMQ} {
            padding: ($unit-small * 0.5) 0;
        }
    }
    .c-form {
        &__item {
            margin-top: $unit-small;

            label {
                span {
                    opacity: 0.6;

                    &.short-label {
                        display: none;
                        @media #{$desktopMQ} {
                            display: block;
                        }
                    }
                    &.long-label {
                        display: none;
                        @media #{$midDesktopMQ} {
                            display: block;
                        }
                    }
                }
            }
        }
        &__input-error {
            position: absolute;
            top: $font-size-small-body / 2;
            right: $gutter;


            @media only screen and (max-width:#{$tablet-h+ 1}) {
                top: $font-size-small-body / 1.5;
                @include font-size($font-size-footer * 0.7);
            }

            @media #{$tabletWMQ} {
                top: $font-size-small-body-mobile / 2.5;
            }
        }
    }

    .c-tab-form {
        &__input {
            padding-top: $unit-small;
            padding-bottom: $unit-small;
        }
        &__input-error {
            position: absolute;
            top: -$unit-small * 0.25;
            right: 0;

            transform: translateY(-100%);
        }

        &__submit {
            @media #{$tabletHMQ} {
                margin-top: $unit * 0.5;
            }
        }
    }
}