.about-page {
    padding-bottom: 10vh;

    .c-hero {
        padding-top: 20vh;

        @media #{$tabletWMQ} {
            padding-top: 10vh;
        }

        &__inner {
            @include flex(center, center, colum, nowrap);
            position: relative;
            padding: 13vh 0;

            img {
                @include absoluteBox;
                top: -5%;
            }

            h1 {
                white-space: nowrap;

                @media only screen and (min-width:#{$mobile}) and (max-width:#{$tablet-w + 1}) {
                    @include font-size($font-size-h1-mobile * 1.5);
                }
            }
        }

        &__decorator {
            margin: 0 auto;
            width: 150px;
            height: 2px;
        }
    }

    .c-illu {
        position: relative;

        &__decorator {
            position: absolute;
            pointer-events: none;
            width: 100%;

            &--top {
                top: 0;
                left: 0;
                transform: scaleY(-1);
            }
            &--bottom {
                bottom: 0;
                right: 0;
                width: 100%;
            }
            svg  {
                display: block;
                width: 100%;

                path {
                    fill: $white;
                }
            }
        }

        &__bg {
            @include absoluteBox;
            opacity: 0.05;
        }

        &__illu {
            margin-bottom: $unit;

            @media #{$mobileMQ} {
                margin-bottom: $unit * 0.25;
            }
        }
    }

    .c-commitment {
        overflow: hidden;

        &__quote {
            position: absolute;
            top: 0;
            left: 0;

            $w: 144px;
            $h: 127px;

            width: $w;
            height: $h;

            margin-top: -$h * 0.6;
            margin-left: -$w* 0.6;

            @media only screen and (min-width:#{$tablet-h}) and (max-width:#{$desktop - 1}) {
                $w: 144px * 0.7;
                $h: 127px * 0.7;

                width: $w;
                height: $h;

                margin-top: -$h * 0.6;
                margin-left: -$w* 0.6;
            }

            @media #{$tabletWMQ} {
                $w: 144px * 0.5;
                $h: 127px * 0.5;

                width: $w;
                height: $h;

                margin-top: -$h * 0.6;
                margin-left: -$w* 0.6;
            }

            @media #{$mobileMQ} {
                width: $w * 0.5;
                height: $h * 0.5;

                margin-left: 0;
                transform: translate(0%, 0%);
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}